import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './style/VideoGallery.css'; // Make sure this path is correct and the file exists
import Header from '../Components/User/Header'
import Footer from '../Components/User/Footer'
const apiKey = 'AIzaSyBy3R2tn_iPZ_o3UjigeS5Zn1Vwj13EhtE'; // Replace with your API key
const channelId = 'UCbxqo5Hyf-vJQHfRyep8TpA'; // Replace with your channel ID

const VideoGallery = () => {
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get('https://www.googleapis.com/youtube/v3/search', {
          params: {
            key: apiKey,
            channelId: channelId,
            part: 'snippet',
            order: 'date',
            maxResults: 10
          }
        });
        setVideos(response.data.items);
      } catch (err) {
        setError(err.message || 'An error occurred while fetching videos.');
      }
    };

    fetchVideos();
  }, []);

  return (
    <>
    <Header/>
    <div className="video-gallery" >
    <section className='text-center my-5'>
        <h1>Video Gallery</h1>
       
      </section>

      {error && <p className="text-danger">Error: {error}</p>}
      {videos.length > 0 ? (
        <div className="video-grid">
          {videos.map(video => (
            <div key={video.id.videoId} className="video-item">
              <a 
                href={`https://www.youtube.com/watch?v=${video.id.videoId}`} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img
                  src={video.snippet.thumbnails.medium.url}
                  alt={video.snippet.title}
                  className="video-thumbnail"
                />
                <h4 className="video-title">{video.snippet.title}</h4>
              </a>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center">No videos available.</p>
      )}
    </div>
    <Footer/>
    </>
  );
};

export default VideoGallery;
