// src/components/RecentArticles.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/RecentArticles.css'; // Import your custom CSS for additional styling

const apiUrl = process.env.REACT_APP_API_URL;

function RecentArticles() {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/blogs`); // Assuming the endpoint for articles is /api/articles
        setArticles(response.data.slice(0, 4));
      } catch (err) {
        setError(err.message);
      }
    };

    fetchArticles();
  }, []);

  if (error) {
    return <p className="text-danger">Error: {error}</p>;
  }

  return (
    <Container className="mt-5 p-4">
      <h2 className="text-center mb-4 ">Recent Articles</h2>
      <Row className="d-flex justify-content-center ">
        {articles.map((article) => (
          <Col xs={12} sm={6} md={4} lg={3} key={article._id} className="mb-4">
            <Link to={`/blogs/${article._id}`} className="text-decoration-none">
              <Card className="text-center card-custom">
                <Card.Img
                  variant="top"
                  src={`${apiUrl}/uploads/${article.image}`}
                  style={{ height: '200px', objectFit: 'cover' }}
                  alt={article.title}
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="card-title">
                      {article.title}
                    </h5>
                  </Card.Title>
                  
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default RecentArticles;
