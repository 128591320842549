import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../Context/authContext';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import Swal from 'sweetalert2'; // Import SweetAlert2

const EditProfile = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { user, updateUser } = useContext(AuthContext);
    const [userData, setUserData] = useState({
        fullName: '',
        contactNumber: '',
        profileImage: null,
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                };
                const response = await axios.get(`${apiUrl}/api/user/profile`, config);
                setUserData({
                    fullName: response.data.fullName,
                    contactNumber: response.data.contactNumber,
                    profileImage: response.data.profileImage,
                });
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchUserProfile();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        if (e.target.files[0].size > 1 * 1024 * 1024) { // Check file size (1 MB limit)
            Swal.fire({
              icon: 'error',
              title: 'File too large',
              text: 'Please select a file smaller than 1 MB.',
            });
            return;
          }
        setUserData({
            ...userData,
            profileImage: e.target.files[0],
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        if (userData.fullName) {
            formData.append('fullName', userData.fullName);
        }
        if (userData.contactNumber) {
            formData.append('contactNumber', userData.contactNumber);
        }
        if (userData.profileImage) {
            formData.append('profileImage', userData.profileImage);
        }

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            };
            const response = await axios.put(`${apiUrl}/api/user/profile`, formData, config);
            //setMessage(response.data.message);
		 setMessage('Profile updated successfully! You need to log out and log back in to update the sidebar image.');
            // Display success message with SweetAlert2
            Swal.fire({
                icon: 'success',
                title: 'Profile Updated',
                text: response.data.message,
                confirmButtonText: 'Okay',
            });

            // Update user data in context and state
            updateUser({
                fullName: userData.fullName,
                contactNumber: userData.contactNumber,
                profileImage: response.data.profileImage, // Update profile image
            });
            setUserData(prevState => ({
                ...prevState,
                profileImage: response.data.profileImage, // Set new profile image
            }));
        } catch (error) {
            console.error('Error updating profile:', error);
            setMessage('Error updating profile');

            // Display error message with SweetAlert2
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error updating profile',
                confirmButtonText: 'Okay',
            });
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-8">
                    <h2 className="mb-4">Edit Profile</h2>
                    {message && <div className="alert alert-info">{message}</div>}
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <label htmlFor="fullName">Full Name:</label>
                            <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                className="form-control"
                                value={userData.fullName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="contactNumber">Contact Number:</label>
                            <input
                                type="text"
                                id="contactNumber"
                                name="contactNumber"
                                className="form-control"
                                value={userData.contactNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group mb-3"><p style={{ color: 'red' }}>Image must be less than 1MB  </p>
                            <label htmlFor="profileImage">Profile Image:</label>
                            <input
                                type="file"
                                id="profileImage"
                                name="profileImage"
                                className="form-control-file"
                                onChange={handleFileChange}
                            />
                           
                        </div>
                        <button type="submit" className="btn btn-primary">Update Profile</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
