import React from 'react';
import styles from './style/AboutUs.module.css'; // Import the CSS module
import Header from '../Components/User/Header';
import Footer from '../Components/User/Footer';

const AboutUs = () => {
  return (
    <div>
      <Header />
          <div className="container my-5 px-5">
      <div className="row">
        <div className="col-12">
          <h2 className="text-center mb-4">About Us</h2>
          <p className="lead">
            Al Basit Group of Companies is a leading and dynamic conglomerate with a strong presence across various industries,
            including real estate, construction, marketing, and event management. Since our inception, we have committed ourselves
            to delivering excellence and innovation through every project we undertake. With a focus on quality, integrity, and customer 
            satisfaction, we strive to provide services that set benchmarks in the industry.
          </p>
        </div>
      </div>
    </div>
      <section id="chairmans-message" className={styles.chairmansMessage}>
        <div className={styles.chairmansMessageContainer}>
          {/* Chairman's Image */}
          <div className={styles.chairmanImageContainer}>
            <img src="./images/Team (4).jpeg" alt="Chairman" className={styles.chairmanImage} />
          </div>
          <h2>Chairman's Message</h2>
          <p>
            Al Basit Group is committed to set new standards and converting ordinary into
            extraordinary. Alhamdulillah, since our incorporation, we have challenged the
            status quo and earned our own mark in the industry. The secret of the rapid
            success of Al Basit Group lies in our sustained leadership and value-added services
            to our clients and business partners. To meet the challenges of modern business, we
            constantly upgrade our operations and services in line with the latest technological 
            facilities. As a responsible entity, we always strive to maintain the highest level 
            of governance, ethical standards, and prudence. Now, under the umbrella of Al Basit 
            Builders & Developers, we are heading towards developing futuristic smart buildings 
            to not only provide luxurious upscale living but also profitable contributions to our clients. 
            I am sure that, with the blessings of Allah (SWT), we will bring revolution in how buildings 
            are made.
          </p>
          <h4>Honorable Chairman <br/> Haji Amjad Mehmood Chaudhry</h4>
        </div>
      </section>

      {/* Corporate Management Section */}
      <section id="corporate-management" className={styles.corporateManagement}>
        <div className={styles.corporateManagementContainer}>
          <h2 className={styles.corporateManagementTitle}>Corporate Management</h2>
          <div className={styles.managementList}>
            <div className={styles.managementItem}>
              <img src="./images/Team (3).jpeg" alt="CEO" />
              <h4>Abdul Basit Chaudhry</h4>
              <p>Director</p>
            </div>
            <div className={styles.managementItem}>
              <img src="./images/Team (2).jpeg" alt="CFO" />
              <h4>Nazakat Hussain</h4>
              <p>Chief Financial Officer</p>
            </div>
		<div className={styles.managementItem}>
		<img src="./images/Team (1).jpeg" alt="Marketing Director" />
		<h4>Aamir Ismat Chaudhry</h4>
		<p>Marketing Director</p>
		</div>
            <div className={styles.managementItem}>
              <img src="./images/Team (6).jpeg" alt="General Manager" />
              <h4>Jehnzaib Saleem</h4>
              <p>General Manager</p>
            </div>
            <div className={styles.managementItem}>
              <img src="./images/Team (5).jpeg" alt="Project Manager" />
              <h4>Engineer Muhammad Ishaq</h4>
              <p>Project Manager</p>
            </div>
            
          </div>
        </div>
      </section>

      {/* Success Timeline Section */}
      <section id="success-timeline" className={styles.successTimeline}>
        <div className={styles.timelineContainer}>
          <h2 className={styles.timelineTitle}>Our Journey of Success</h2>
          <ul className={styles.timeline}>
            <li className={`${styles.timelineItem} ${styles.timelineItemOdd}`}>
              <div className={styles.timelineContent}>
                <h4><i className="fas fa-school"></i> Educational Institute</h4>
                <p>Roots IVY</p>
              </div>
            </li>
            <li className={`${styles.timelineItem} ${styles.timelineItemEven}`}>
              <div className={styles.timelineContent}>
                <h4><i className="fas fa-graduation-cap"></i> Educational Institute</h4>
                <p>EDU 21</p>
              </div>
            </li>
            <li className={`${styles.timelineItem} ${styles.timelineItemOdd}`}>
              <div className={styles.timelineContent}>
                <h4><i className="fas fa-building"></i> Commercial Building</h4>
                <p>CAM 5 GT Road</p>
              </div>
            </li>
            <li className={`${styles.timelineItem} ${styles.timelineItemEven}`}>
              <div className={styles.timelineContent}>
                <h4><i className="fas fa-hotel"></i> Hotel</h4>
                <p>Grand Mangolia</p>
              </div>
            </li>
            <li className={`${styles.timelineItem} ${styles.timelineItemOdd}`}>
              <div className={styles.timelineContent}>
                <h4><i className="fas fa-hands-helping"></i> Welfare</h4>
                <p>Welfare Trust</p>
              </div>
            </li>
            <li className={`${styles.timelineItem} ${styles.timelineItemEven}`}>
              <div className={styles.timelineContent}>
                <h4><i className="fas fa-futbol"></i> Sports</h4>
                <p>Tent Pegging Club</p>
              </div>
            </li>
            <li className={`${styles.timelineItem} ${styles.timelineItemOdd}`}>
              <div className={styles.timelineContent}>
                <h4><i className="fas fa-gas-pump"></i> Petroleum</h4>
                <p>Attock Petroleum</p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AboutUs;
