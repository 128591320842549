import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"; // Import SweetAlert2
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const apiUrl = process.env.REACT_APP_API_URL;

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    password: "",
    role: "editor", // Default role
    profileImage: null,
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    if (e.target.files[0].size > 1 * 1024 * 1024) { // Check file size (1 MB limit)
      Swal.fire({
        icon: 'error',
        title: 'File too large',
        text: 'Please select a file smaller than 1 MB.',
      });
      return;
    }
    setFormData({ ...formData, profileImage: e.target.files[0] });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    Object.keys(formData).forEach((key) =>
      formDataObj.append(key, formData[key])
    );

    try {
      const response = await axios.post(
        `${apiUrl}/api/auth/register`,
        formDataObj,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: response.data.message,
      }).then(() => {
        navigate('/dashboard');
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        Swal.fire({
          icon: 'error',
          title: 'Email already registered',
          text: 'Please use a different email address.',
        });
      } else if (error.response && error.response.data.message === 'File too large') {
        Swal.fire({
          icon: 'error',
          title: 'File too large',
          text: 'Please select a file smaller than 1 MB.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Registration failed. Please try again later.',
        });
      }
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="row w-100">
        <div className="col-lg-8 col-md-8 col-sm-12 mx-auto">
          <div className="form p-4 cardstyle">
            <h2 className="text-center mb-4">✦ Registration Form ✦</h2>
            <form onSubmit={handleRegister}>
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="fullName"
                  className="form-control"
                  placeholder="Full Name"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="contactNumber"
                  className="form-control"
                  placeholder="Contact Number"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <select
                  name="role"
                  className="form-control"
                  onChange={handleChange}
                  required
                >
                  <option value="editor">Editor</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
              <div className="form-group mb-3">
                <input
                  type="file"
                  name="profileImage"
                  className="custom-file-input"
                  onChange={handleFileChange}
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">Register</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
