import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './style/Csr.css'; // Import your CSS file for styling and animations
import Header from '../Components/User/Header';
import Footer from '../Components/User/Footer';

const apiUrl = process.env.REACT_APP_API_URL;

const Csr = () => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/projects`);
        // Filter projects to only include education type
        const welfareProjects = response.data.filter(project => project.projectType === 'construction');
        setProjects(welfareProjects);
       
      } catch (err) {
        setError(err.message);
      }
    };

    fetchProjects();
  }, []);

  // Helper function to truncate description
  const truncateDescription = (description, length = 100) => {
    const text = description.replace(/(<([^>]+)>)/gi, ''); // Strip HTML tags
    return text.length > length
      ? `${text.substring(0, length)}...`
      : text;
  };

  return (
    <div>
      <Header/>
      {/* Main Banner */}
      <section className="banner">
        <h1>Corporate Social Responsibility</h1>
        <p>#WeCare</p>
      </section>

      {/* Info Section */}
      <section className="info-section text-justify mx-4">
        <h2 className='text-center my-4'>Social Welfare Initiatives</h2>
        <p>
          All Albasit Group of Comapany's project and initiatives under the Corporate Social
          Responsibility Division are aimed towards the betterment of the
          underprivileged and helping those most in need of help the most.
        </p>
      </section>

      {/* Projects Section */}
      <section className="projects-section">
        {error && <p className="text-danger">Error: {error}</p>}
        {projects.length > 0 ? (
          projects.map((project, index) => (
            <div key={project._id} className={`project-item ${index % 2 === 0 ? 'left' : 'right'}`}>
              <div className="project-content">
                <div className="project-image-container">
                  <img
                    src={`${apiUrl}/uploads/${project.mainImage}`}
                    alt={project.title}
                    className="project-image"
                  />
                </div>
                <div className="project-text">
                  <h3 className="project-title">{project.title}</h3>
                  <p 
                    className="project-description"
                    dangerouslySetInnerHTML={{ __html: truncateDescription(project.description, 100) }}
                  />
                  <Link to={`/projects/${project._id}`} className="see-more-link">See More</Link>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No welfare projects available at the moment.</p>
        )}
      </section>
      <Footer/>
    </div>
  );
};

export default Csr;
