import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
const apiUrl = process.env.REACT_APP_API_URL;

const Comments = () => {
  const [comments, setComments] = useState([]);

  // Fetch all Comments when the component mounts
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/comments`);
        // Sort comments with 'unread' first
        const sortedComments = response.data.sort((a, b) => (a.status === 'unread' ? -1 : 1));
        setComments(sortedComments);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchComments();
  }, []);

  // Handle marking a message as read
  const handleMarkAsRead = async (messageId) => {
    try {
      const response = await axios.patch(`${apiUrl}/api/comments/${messageId}/markAsRead`);
      // Update and sort comments with 'unread' first
      const updatedComments = comments.map((message) =>
        message._id === messageId ? response.data : message
      );
      const sortedComments = updatedComments.sort((a, b) => (a.status === 'unread' ? -1 : 1));
      setComments(sortedComments);
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  return (
    <div className="container mt-4">
      <h3 className="mb-4">Contact comments</h3>
      {comments.length === 0 ? (
        <p>No comments available.</p>
      ) : (
        comments.map((message) => (
          <div key={message._id} className="card mb-3 position-relative">
            <div className="card-body">
              <h5 className="card-title">{message.name}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{message.email}</h6>
              <p className="card-text">{message.comment}</p>

              <p className="card-text">
                <small className="text-muted">
                  Posted at: {new Date(message.postedAt).toLocaleString()}
                </small>
              </p>
              <div className="d-flex justify-content-end align-items-center position-absolute bottom-0 end-0 p-2">
                {message.status === 'unread' && (
                  <button 
                    className="btn btn-link text-secondary me-2" 
                    onClick={() => handleMarkAsRead(message._id)}
                    aria-label="Mark as Read"
                  >
                    <i className="fas fa-check"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Comments;
