import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Line, Pie } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style/performance.css"; // Import the CSS file for hover animation

// Register Chart Components
ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend
);
const apiUrl = process.env.REACT_APP_API_URL;
const PerformanceDashboard = () => {
  const [totalSubscribers, setTotalSubscribers] = useState(0);
  const [monthlyIncrease, setMonthlyIncrease] = useState(0);
  const [totalcontacts, settotalContacts] = useState(0);
  const [monthlyIncreaseContact, setMonthlyIncreaseContact] = useState(0);
  const [blogFeedback, setBlogFeedback] = useState({ unread: 0, read: 0 });
  const [latestBlog, setLatestBlog] = useState({});
  const [latestProject, setLatestProject] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subscribersResponse = await axios.get(
          `${apiUrl}/api/stats/subscribers`
        );
        const messagesResponse = await axios.get(
          `${apiUrl}/api/stats/contactMessagesthismonth`
        );
        const feedbackResponse = await axios.get(
          `${apiUrl}/api/stats/blogFeedback`
        );
        const latestBlogResponse = await axios.get(
          `${apiUrl}/api/stats/latest-blog`
        );
        const latestProjectResponse = await axios.get(
          `${apiUrl}/api/stats/latest-project`
        );

        setTotalSubscribers(subscribersResponse.data.totalSubscribers);
        setMonthlyIncrease(subscribersResponse.data.monthlyIncrease);
        settotalContacts(messagesResponse.data.totalContacts);
        setMonthlyIncreaseContact(messagesResponse.data.monthlyIncrease)
        setBlogFeedback(feedbackResponse.data);
        setLatestBlog(latestBlogResponse.data);
        setLatestProject(latestProjectResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const totalSubscribersData = {
    labels: ["Last Month", "This Month"],
    datasets: [
      {
        label: "Subscribers",
        data: [totalSubscribers - monthlyIncrease, totalSubscribers],
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 3,
        tension: 0.4,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };

  const totalSubscribersOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Subscribers",
        },
      },
    },
  };
  const totalContacts = {
    labels: ["Last Month", "This Month"],
    datasets: [
      {
        label: "Contacts",
        data: [totalcontacts - monthlyIncreaseContact, totalcontacts],
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 3,
        tension: 0.4,
        pointBackgroundColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };

  const totalContactOtions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Contacts",
        },
      },
    },
  };



  const feedbackData = {
    labels: ["Unread", "Read"],
    datasets: [
      {
        data: [blogFeedback.unread, blogFeedback.read],
        backgroundColor: ["#FFCE56", "#4BC0C0"],
        hoverBackgroundColor: ["#FFCE56", "#4BC0C0"],
      },
    ],
  };

  return (
    <>
      <div className="performance-dashboard container mx-0">
        <h3>Website Performance</h3>
        <div className="row ">
          {/* Existing cards */}
          <div className="col-lg-4 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Total Subscribers & Monthly Increase
                </h5>
                <Line
                  data={totalSubscribersData}
                  options={totalSubscribersOptions}
                  
                />
                <p>
                  Monthly Increase: {monthlyIncrease} (
                  {Math.round(
                    (monthlyIncrease / (totalSubscribers - monthlyIncrease)) *
                      100
                  )}
                  %)
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Contact Messages</h5>
                <Line
                  data={totalContacts}
                  options={totalContactOtions}
                  
                />
                <p>
                  Monthly Increase: {monthlyIncreaseContact} (
                    
                  {Math.round(
                    (monthlyIncreaseContact / (totalcontacts - monthlyIncreaseContact)) *
                      100
                  )}
                  %)
                </p>
                <Link to="/messages" className="btn btn-primary mt-3">
                  View Messages
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Blog Feedback/Comments</h5>
                <Pie
                  data={feedbackData} // Set width here
                />
                <Link to="/comments" className="btn btn-primary mt-3">
                  View Comments
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <h3 className="text-center">Latest</h3>

          {/* New cards for latest blog and project */}
          <div className="col-md-6 mb-3">
            <div className="card hover-card">
              <div className="card-body">
                <img
                  src={`${apiUrl}/uploads/${latestBlog.image}`}
                  className="card-img-top"
                  style={{ height: "200px", objectFit: "cover", width: "100%" }}
                />

                <h5
                  className="card-title"
                  style={{
                    height: "30px",
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  {latestBlog.title}
                </h5>
              </div>
              <div
                className="hover-effect"
                onClick={() => navigate("/add-blog")}
              >
                <span>+</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="card hover-card">
              <div className="card-body">
                <img
                  src={`${apiUrl}/uploads/${latestProject.mainImage}`}
                  className="card-img-top"
                  style={{ height: "200px", objectFit: "cover", width: "100%" }}
                />

                <h5
                  className="card-title"
                  style={{
                    height: "30px",
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  {latestProject.title}
                </h5>
              </div>
              <div
                className="hover-effect"
                onClick={() => navigate("/add-project")}
              >
                <span>+</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceDashboard;
