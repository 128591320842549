import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const CommentForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    comment: '',
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const [message, setMessage] = useState('');

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to submit the comment
      const response = await axios.post(`${apiUrl}/api/comments`, formData);
      setMessage('Comment submitted successfully!');
      setFormData({
        name: '',
        email: '',
        comment: '',
      });
    } catch (error) {
      console.error('Error submitting comment:', error);
      setMessage('Error submitting comment. Please try again.');
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div className="col-md-8 col-lg-8">
        <h3 className="text-center mb-4">Submit Your Comment</h3>
        <form onSubmit={handleSubmit} className="row g-3">
          <div className="col-12">
            <label htmlFor="name" className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12">
            <label htmlFor="comment" className="form-label">Comment</label>
            <textarea
              className="form-control"
              id="comment"
              name="comment"
              rows="4"
              value={formData.comment}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-primary w-100">Submit</button>
          </div>
          {message && (
            <div className="col-12 mt-3">
              <div className="alert alert-info" role="alert">
                {message}
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CommentForm;
