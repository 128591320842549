// src/components/BlogList.js

import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Dropdown } from "react-bootstrap";
import { FaSortUp, FaSortDown,FaEdit, FaTrash } from "react-icons/fa"; // Import sort icons
// import "../List.css"; // Import custom CSS for additional styling
import { AuthContext } from "../../Context/authContext"; // Import AuthContext

const apiUrl = process.env.REACT_APP_API_URL;

function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc"); // Default to descending order
  const navigate = useNavigate();
  const { user } = useContext(AuthContext); // Use AuthContext
  const userRole = user ? user.role : 'guest';
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/blogs`);
        setBlogs(response.data);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching blogs:", err);
      }
    };

    fetchBlogs();
  }, []);

  useEffect(() => {
    // Function to sort blogs based on the sort order
    const sortBlogs = (blogs) => {
      return blogs.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    };

    setBlogs((prevBlogs) => sortBlogs([...prevBlogs]));
  }, [sortOrder]);

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this blog?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.delete(
                `${apiUrl}/api/blogs/${id}`
              );
              if (response.status === 200) {
                setBlogs(blogs.filter((blog) => blog._id !== id));
              
              } else {
                alert("Failed to delete blog");
              }
            } catch (err) {
              alert("Failed to delete blog");
              console.error("Error deleting blog:", err);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleEdit = (id) => {
    navigate(`/blogs/edit/${id}`);
  };

  const handleSortToggle = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  return (
    
      
      <div className="container">
      <div className="d-flex mb-4">
       
      <div className="d-flex btn-container">
          <div className="sort-container">
            <button onClick={handleSortToggle} className="btn btn-info ml-3">
              {sortOrder === "asc" ? (
                <FaSortUp className="sort-icon" />
              ) : (
                <FaSortDown className="sort-icon" />
              )}
              <span className="sort-text" style={{ color: 'white' }}>Sort</span>
            </button>
          </div>
          {['admin', 'editor'].includes(userRole) && (
            <Link to="/add-blog" className="btn btn-primary mr-2">
              Add Blog
            </Link>
          )}
        </div>
      </div>
      {error && <p className="text-danger">Error: {error}</p>}
      <div className="row ">
        {blogs.map((blog) => (
          <div className="col-md-4 mb-4" key={blog._id}>
            <div className="card">
              <Link to={`/blogs/${blog._id}`} className="text-decoration-none">
                <img
                  src={`${apiUrl}/uploads/${blog.image}`}
                  className="card-img-top"
                  alt={blog.title}
                  style={{ height: "200px", objectFit: "cover", width: "100%" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      height: "30px",
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    {blog.title}
                  </h5>
                  <p className="card-footer">
                    <b>By:</b> {blog.author}
                    <br />
                    <b>Date:</b>{" "}
                    {new Date(blog.date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                </div>
              </Link>
              {['admin', 'editor'].includes(userRole) && (
               <div className="d-flex justify-content-center px-2">
               <button onClick={() => handleEdit(blog._id)} className="btn btn-cutsom m-1">
                 <FaEdit />
               </button>
               <button onClick={() => handleDelete(blog._id)} className="btn btn-transparent m-1">
                 <FaTrash />
               </button>
             </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BlogList;
