
import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import '../style/Form.css'; // Import your custom CSS file

const apiUrl = process.env.REACT_APP_API_URL;

function ProjectForm() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [mainImage, setMainImage] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [category, setCategory] = useState('upcoming');
  const [completionDate, setCompletionDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [expectedCompletionDate, setExpectedCompletionDate] = useState('');
  const [projectType, setProjectType] = useState('construction'); // Add projectType state
  const navigate = useNavigate();

  const handleMainImageChange = (e) => {
  if (e.target.files[0].size > 1 * 1024 * 1024) { // Check file size (1 MB limit)
    Swal.fire({
      icon: 'error',
      title: 'File too large',
      text: 'Please select a file smaller than 1 MB.',
    });
    return;
  }
  setMainImage(e.target.files[0]); // Ensure this is set correctly
};

  const handleGalleryImagesChange = (e) => {
    if (e.target.files[0].size > 1 * 1024 * 1024) { // Check file size (1 MB limit)
      Swal.fire({
        icon: 'error',
        title: 'File too large',
        text: 'Please select a file smaller than 1 MB.',
      });
      return;
    }
    setGalleryImages(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('category', category);
    formData.append('mainImage', mainImage);
    galleryImages.forEach((file, index) => {
      formData.append('galleryImages', file);
    });
    formData.append('projectType', projectType); // Append projectType to form data
    formData.append('completedDate', category === 'completed' ? completionDate : '');
    formData.append('startingDate', category === 'upcoming' ? startDate : '');
    formData.append('expectedCompletionDate', category === 'ongoing' ? expectedCompletionDate : '');

    try {
      const response = await axios.post(`${apiUrl}/api/projects`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 201) {
        Swal.fire({
          title: 'Success!',
          text: 'Project added successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate('/projects');
        });

        // Reset form
        setTitle('');
        setDescription('');
        setCategory('upcoming');
        setCompletionDate('');
        setStartDate('');
        setExpectedCompletionDate('');
        setMainImage(null);
        setGalleryImages([]);
        setProjectType('construction'); // Reset projectType
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to add project.',
        icon: 'error',
        confirmButtonText: 'Try Again',
      });
      console.error(error);
    }
  };

  return (
    <>
      <Link to="/projects" className="btn btn-secondary mb-3">Back to Projects</Link>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            className="quill-editor"
            placeholder="Write project description here..."
          />
        </div>
        <br />
        <div className="form-group"><p style={{ color: 'red' }}>Image must be less than 1MB </p>
          <label htmlFor="mainImage">Main Image</label>
          <input
            type="file"
            id="mainImage"
            className="custom-file-input"
            onChange={handleMainImageChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="galleryImages">Gallery Images</label>
          <input
            type="file"
            id="galleryImages"
            className="custom-file-input"
            onChange={handleGalleryImagesChange}
            multiple
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            className="form-control"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="upcoming">Upcoming</option>
            <option value="ongoing">Ongoing</option>
            <option value="completed">Completed</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="projectType">Project Type</label>
          <select
            id="projectType"
            className="form-control"
            value={projectType}
            onChange={(e) => setProjectType(e.target.value)}
            required
          >
            <option value="construction">Construction</option>
            <option value="welfare">Welfare</option>
            <option value="sport">Sport</option>
            <option value="education">Education</option>
            <option value="hotel industry">Hotel Industry</option>
            <option value="commercial">Commercial</option>
          </select>
        </div>
        {category === 'completed' && (
          <div className="form-group">
            <label htmlFor="completionDate">Completion Date</label>
            <input
              type="date"
              id="completionDate"
              className="form-control"
              value={completionDate}
              onChange={(e) => setCompletionDate(e.target.value)}
            />
          </div>
        )}
        {category === 'upcoming' && (
          <div className="form-group">
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              id="startDate"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
        )}
        {category === 'ongoing' && (
          <div className="form-group">
            <label htmlFor="expectedCompletionDate">Expected Completion Date</label>
            <input
              type="date"
              id="expectedCompletionDate"
              className="form-control"
              value={expectedCompletionDate}
              onChange={(e) => setExpectedCompletionDate(e.target.value)}
            />
          </div>
        )}
        <button type="submit" className="btn btn-primary">Add Project</button>
      </form>
    </>
  );
}

export default ProjectForm;
