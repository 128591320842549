import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/Details.css'; // Custom CSS for additional styling
import Header from '../User/Header';
import Footer from '../User/Footer';
import RecentArticles from '../User/RecentArticles';
import { AuthContext } from '../../Context/authContext'; // Adjust the path to your AuthContext
import CommentForm from '../Admin/Commentform';

const apiUrl = process.env.REACT_APP_API_URL;

function BlogDetails() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const { user } = useContext(AuthContext); // Get the user role from the auth context
  const userRole = user ? user.role : 'guest';

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/blogs/${id}`);
        setBlog(response.data);
      } catch (err) {
        setError(err.message || 'Something went wrong while fetching the blog details.');
      }
    };
  
    const fetchRecentBlogs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/blogs/recent`);
        setRecentBlogs(response.data);
      } catch (err) {
        console.error('Error fetching recent blogs:', err.message);
      }
    };
  
    // Only fetch blog if id is valid
    if (id && id !== 'recent') {
      fetchBlog();
    }
  
    fetchRecentBlogs();
  }, [id]);
  
  if (error) {
    return (
      <div className="container mt-5">
        <p className="text-danger text-center">Error: {error}. Please try refreshing the page or check your connection.</p>
      </div>
    );
  }

  if (!blog) {
    return (
      <div className="container mt-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      {['guest'].includes(userRole) && <Header />}
      
      <div className="container mt-4">
        <div className="details">
          <div className="row">
            {/* Main Content */}
            <div className="col-md-12">
              {/* Full-Width Main Image */}
              <div className="text-center mb-4">
                <img
                  src={`${apiUrl}/uploads/${blog.image}`}
                  className="img-fluid blog-main-image"
                  alt={blog.title}
                  onError={(e) => (e.target.src = '/default-image.jpg')}
                />
              </div>

              {/* Blog Title */}
              <h2 className="text-center mb-4 title">{blog.title}</h2>

              {/* Blog Description */}
              <div
                className="detail-description mb-5"
                dangerouslySetInnerHTML={{ __html: blog.description }}
              />
            </div>
          </div>
          
          {/* Only show CommentForm and Recent Articles if the user is not an admin or editor */}
          {['guest'].includes(userRole) && (
            <div className="mt-4">
              <CommentForm />
            </div>
          )}
        </div>
        
        {/* Display Recent Articles */}
        {['guest'].includes(userRole) && <RecentArticles />}
      </div>

      {['guest'].includes(userRole) && <Footer />}
    </>
  );
}

export default BlogDetails;
