import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function NewsletterForm() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/newsletter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();

      if (response.ok) {
        setEmail('');
        setMessage('Thank you for subscribing to our newsletter!');
      } else {
        setMessage(result.message || 'Failed to subscribe. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4 text-center">Subscribe to Our Newsletter</h2>
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 mx-3">
          <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
            <div className="form-group mb-3  w-100">
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">Subscribe</button>
          </form>
          {message && <p className="mt-3 text-center">{message}</p>}
        </div>
      </div>
    </div>
  );
}

export default NewsletterForm;
