// src/components/AdminEmailForm.js
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;
function AdminEmailForm() {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(`${apiUrl}/api/email/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ subject, message }),
    });

    if (response.ok) {
      setSubject('');
      setMessage('');
      alert('Newsletter sent successfully!');
    } else {
      alert('Failed to send newsletter. Please try again.');
    }
  };

  return (
    <>
     <Link to="/newsleter"></Link>
    <div className="container mt-4 p-5">
      <h2 className="mb-4">Send Newsletter</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            className="form-control"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            className="form-control"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="10"
            required
          />
         
        </div>
        <button type="submit" className="btn btn-primary">Send</button>
      </form>
    </div>
    </>
  );
}

export default AdminEmailForm;
