import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner'; // Import Spinner from react-bootstrap
import '../style/Projectdetails.css';
import Header from '../User/Header';
import Footer from '../User/Footer';
import { AuthContext } from '../../Context/authContext'; 
import ProjectListUser from '../User/ProjectListUser';

const apiUrl = process.env.REACT_APP_API_URL;

function ProjectDetails() {
  const { id } = useParams();
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [project, setProject] = useState(null);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext); // Get the user role from the auth context
  const userRole = user ? user.role : 'guest';

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/projects/${id}`);
        setProject(response.data);
      } catch (err) {
        setError(err.message || 'Something went wrong while fetching the project details.');
      }
    };

    fetchProject();
  }, [id]);

  if (error) {
    return <p className="text-danger">Error: {error}. Please try refreshing the page or check your connection.</p>;
  }

  if (!project) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // Function to handle button click
  const handleInvestNowClick = () => {
    navigate('/invest');
  };

  return (
    <>
      {['guest'].includes(userRole) && <Header />}
      <div className="details">
        {/* Full-Width Main Image */}
        <div className="text-center mb-4">
          <img
            src={`${apiUrl}/uploads/${project.mainImage}`}
            className="img-fluid"
            alt={project.title}
            onError={(e) => (e.target.src = '/default-image.jpg')} // Fallback image
          />
        </div>

        {/* Project Title */}
        <h2 className="text-center mb-4 title">{project.title}</h2>

        {/* Project Description */}
        <div
          className="detail-description mb-5"
          dangerouslySetInnerHTML={{ __html: project.description }}
        />

        {/* Image Gallery (if there are multiple images) */}
        {project.galleryImages && project.galleryImages.length > 0 && (
          <div className="gallery-container">
            {project.galleryImages.map((img, index) => (
              <div key={index} className="gallery-item">
                <img
                  src={`${apiUrl}/uploads/${img}`}
                  alt={`Gallery Image ${index + 1}`}
                  onError={(e) => (e.target.src = '/default-gallery-image.jpg')} // Handle missing gallery images
                />
              </div>
            ))}
          </div>
        )}

        {/* Invest Now Button */}
        {(project.category === 'upcoming' || project.category === 'ongoing') && (
          <div className="text-center mt-4">
            <button
              className="btn btn-primary"
              onClick={handleInvestNowClick}
            >
              Invest Now
            </button>
          </div>
        )}
      </div>
      {['guest'].includes(userRole) && <ProjectListUser />}
      {['guest'].includes(userRole) && <Footer />}
    </>
  );
}

export default ProjectDetails;
