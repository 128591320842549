import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './style/onemore.css';
import Header from '../Components/User/Header';
import Footer from '../Components/User/Footer';
import ProjectList from '../Components/Common/ProjectList';

import { useNavigate } from 'react-router-dom';
function Project() {
 const apiUrl = process.env.REACT_APP_API_URL;
 const { category } = useParams(); // Get category from URL parameters
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [error, setError] = useState(null);
  const [filterType, setFilterType] = useState(category || 'all'); // Set initial filterType based on URL parameter

  const navigate = useNavigate(); // Initialize navigate function

  const handleTypeChange = (type) => {
    navigate(`/projects?projectType=${type}`); // Programmatically navigate
  };

  return (
    <div className="">
      <Header />
      <div className="custom-component">
        <div className="image-container-f position-relative">
          <img
            src="/images/img (2).jpg"
            alt="Sample"
            className="img-fluid w-100 fixed-height"
          />
          <div className="text position-absolute top-50 start-50 translate-middle text-center text-white">
            <h2>OUR PROJECTS</h2>
            <p style={{ color: "white" }}>Discover our diverse range of innovative real estate developments designed to enhance modern living and foster community growth.

</p>
          </div>
        </div>
        <header className="header">
          <div className="project-type-buttons mt-3 mt-md-0 ms-md-3">
          <button onClick={() => handleTypeChange('all')} className="btn btn-custom">All</button>
      <button onClick={() => handleTypeChange('construction')} className="btn btn-custom">Construction</button>
      <button onClick={() => handleTypeChange('welfare')} className="btn btn-custom">Welfare</button>
      <button onClick={() => handleTypeChange('sport')} className="btn btn-custom">Sport</button>
      <button onClick={() => handleTypeChange('education')} className="btn btn-custom">Education</button>
      <button onClick={() => handleTypeChange('hotel industry')} className="btn btn-custom">Hotel</button>
      <button onClick={() => handleTypeChange('industry')} className="btn btn-custom">Industry</button>
      <button onClick={() => handleTypeChange('commercial')} className="btn btn-custom">Commercial</button>
          </div>
        </header>

        {error && <p className="text-danger">Error: {error}</p>}
        <div className="row mx-4">
          <ProjectList projects={filteredProjects} /> {/* Pass filtered projects to ProjectList */}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Project;
