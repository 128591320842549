import React, { useState, useEffect } from "react";
import "../style/ImageTextSlider.css"; // Import the CSS file for styling and animations

const ImageTextSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTextVisible, setIsTextVisible] = useState(false);

  const images = [
    {
      src: "/images/img (2_up).jpg",
      text: "Transforming Communities Through Innovative Real Estate Development",
      description:
        "Al Basit Group sets the benchmark for real estate excellence by delivering innovative projects that blend modern architecture with sustainable infrastructure. Each project is meticulously designed to meet the demands of contemporary living, offering communities spaces that enhance both lifestyle and functionality. ",
    },
    {
      src: "/images/img (3_up).JPG",
      text: "A Diverse Portfolio Encompassing Multiple Industries for Holistic Growth",
      description: "With ventures spanning education, petroleum, hospitality, and sports, Al Basit Group brings expertise and excellence to each sector. Our real estate projects are designed to support modern lifestyle demands and community growth.",
    },
    {
      src: "/images/img (1).JPG",
      text: "Visionary Leadership Fueling Innovation and Growth",
      description: "Under the dynamic leadership of Chairman Amjad Mehmood, Al Basit Group has evolved since 2004 into a pioneering force across multiple industries. Our projects are designed to deliver value-added services and sustainable solutions, ensuring we meet the needs of our clients and partners while driving progress in every sector we engage.",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTextVisible(true); // Show text after image is displayed
      const nextIndex = (currentIndex + 1) % images.length;
      setTimeout(() => {
        setIsTextVisible(false); // Hide text before changing image
        setTimeout(() => {
          setCurrentIndex(nextIndex); // Change to the next image
        }, 500); // Duration to wait before changing the image
      }, 3000); // Duration for text visibility
    }, 1000); // Delay before showing text

    return () => clearTimeout(timer);
  }, [currentIndex, images.length]);

  return (
    <div className="image-text-slider">
      <div className="image-container">
        <img
          src={images[currentIndex].src}
          alt={`Slide ${currentIndex}`}
          className="slide-image"
        />
        <div className={`text-overlay ${isTextVisible ? "show" : ""}`}>
          <h1 className="main-text">{images[currentIndex].text}</h1>
          <p className="description">{images[currentIndex].description}</p>
        </div>
      </div>
    </div>
  );
};

export default ImageTextSlider;
