import React from 'react';

const Albasit = () => {
    return (
        <>
            {/* Main Banner Section */}
            <section className="main-banner">
                <h1>Your Real Estate Project - Peshawar</h1>
                <p>Thank you for making it a success!</p>
            </section>

            {/* Information Section */}
            <section className="info-section">
                <h2>Your Real Estate Brand - Peshawar</h2>
                <p>Continuing the legacy of quality living. Our latest project is here.</p>
                <p>Membership is now closed.</p>
                <div className="info-container">
                    <form action="#" method="post">
                        <label htmlFor="name">Name *</label>
                        <input type="text" id="name" name="name" required />

                        <label htmlFor="country">Country *</label>
                        <select id="country" name="country" required>
                            <option value="" disabled selected>Select Country</option>
                            <option value="Pakistan">Pakistan</option>
                            {/* Add more countries as options */}
                        </select>

                        <label htmlFor="city">City *</label>
                        <input type="text" id="city" name="city" required />

                        <label htmlFor="mobile">Mobile No *</label>
                        <input type="tel" id="mobile" name="mobile" required />

                        <label htmlFor="email">Email Address *</label>
                        <input type="email" id="email" name="email" required />

                        <label htmlFor="comments">Comments</label>
                        <textarea id="comments" name="comments"></textarea>

                        <button type="submit">Submit</button>
                    </form>
                </div>
            </section>

            {/* Video Section */}
            <section className="video-section">
                <div className="video-grid">
                    <div className="video-item">
                        <iframe src="https://www.youtube.com/watch?v=SqcY0GlETPk" frameBorder="0" allowFullScreen></iframe>
                        <p>Announcement 1</p>
                    </div>
                    <div className="video-item">
                        <iframe src="https://www.youtube.com/watch?v=SqcY0GlETPk" frameBorder="0" allowFullScreen></iframe>
                        <p>Announcement 2</p>
                    </div>
                    <div className="video-item">
                        <iframe src="https://www.youtube.com/watch?v=SqcY0GlETPk" frameBorder="0" allowFullScreen></iframe>
                        <p>Announcement 3</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Albasit;
