
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Quill styling
import '../style/Form.css' // Import the updated CSS file
const apiUrl = process.env.REACT_APP_API_URL;
function BlogForm() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [author, setAuthor] = useState('');
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    if (e.target.files[0].size > 1 * 1024 * 1024) { // Check file size (1 MB limit)
      Swal.fire({
        icon: 'error',
        title: 'File too large',
        text: 'Please select a file smaller than 1 MB.',
      });
      return;
    }
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('author', author);
    formData.append('image', image);

    try {
      await axios.post(`${apiUrl}/api/blogs`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire({
        title: 'Success!',
        text: 'Blog added successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/blogs');
      });

      setTitle('');
      setDescription('');
      setAuthor('');
      setImage(null);
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to add blog.',
        icon: 'error',
        confirmButtonText: 'Try Again',
      });
      console.error(error);
    }
  };

  return (<> <Link to="/blogs" className="btn btn-secondary mb-3">Back to Blogs</Link>
    <div className="form">
      <h2 className="form-header">✦ Add Blog ✦</h2>
      <form onSubmit={handleSubmit} className="blog-form">
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            className="form-control"
             placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            className="quill-editor"
            placeholder="Write your blog description here..."
          />
        </div>
        <br/>
      
        <div className="form-group">
          <label htmlFor="author">Author</label>
          <input
            type="text"
            id="author"
            className="form-control"
            placeholder="Title"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            className="custom-file-input"
            onChange={handleImageChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Add Blog
        </button>
      </form>
    </div>
    </>
  );
}

export default BlogForm;
