import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/contactform.css';
import Header from '../Components/User/Header';
import Footer from '../Components/User/Footer';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
function Invest() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
 
  const [category, setCategory] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = {
      name,
      email,
      subject,
      category,
    };
  
    try {
      await axios.post(`${apiUrl}/api/messages`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      Swal.fire({
        title: 'Success!',
        text: 'Registered Successfully \nWe will get back to your soon',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/');
      });
  
      // Clear form fields
      setName('');
      setEmail('');
      setSubject('');
    
      setCategory('');
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to Book.',
        icon: 'error',
        confirmButtonText: 'Try Again',
      });
      console.error(error);
    }
  };
  

       

        
  return (
    <div className="">
      <Header />
      <h2 className="mb-4 text-center p-4">Contact Us</h2>
      <section className="contact-section">
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Phone</label>
              <input
                type="text"
                className="form-control"
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="category">Interested To Invest In</label>
              <select
                id="category"
                className="form-control"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              >
                <option value="">Select an option</option>
                <option value="Civic Avenue Mall">Civic Avenue Mall</option>
                <option value="AlBasit Hills Farmhouses">AlBasit Hills Farmhouses</option>
                <option value="Cam Heights">Cam Heights</option>
                <option value="Paragon">Paragon</option>
                <option value="Regal">Regal</option>
               
              </select>
            </div>
            
            <button type="submit" className="btn btn-primary">Book  Now </button>
          </form>
        </div>
      
      </section>
      <Footer />
    </div>
  );
}

export default Invest
