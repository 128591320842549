
import React from 'react';
import ProjectList from '../Components/Common/ProjectList';
import Footer from '../Components/User/Footer';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Header from '../Components/User/Header';
import BlogList from '../Components/Common/BlogList';
import './style/onemore.css';
function Blog() {
  return (
  <><Header/>
    <div className="custom-component">
      
      {/* Image Section */}
      <div className="image-container-f position-relative mb-5">
        <img
          src="/images/img (3).JPG"
          alt="Sample"
          className="img-fluid w-100 fixed-height"
        />
        <div className="text position-absolute top-50 start-50 translate-middle text-center text-white">
          <h2>Our Articles</h2>
       
        </div>
      </div>

      <div className="row mx-4">
        <h3 className="text-center mb-4">Recent Articles</h3>
        <BlogList />
      </div>

      <Footer />

      {/* Internal CSS */}
     
    </div>
    </>
  );
}

export default Blog;
