import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const apiUrl = process.env.REACT_APP_API_URL;

const MessagesDashboard = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/messages`);
        const sortedMessages = response.data.sort((a, b) => (a.status === 'unread' ? -1 : 1));
        setMessages(sortedMessages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  const handleExportCSV = () => {
    const headers = ['Name', 'Email', 'Phone', 'Category', 'Sent At'];
    const rows = messages.map((message) => [
      message.name,
      message.email,
      message.subject, // Assuming "Phone" refers to "Subject" here
      message.category,
      new Date(message.sentAt).toLocaleString(),
    ]);

    const csvContent = [
      headers.join(','),
      ...rows.map((row) => row.join(',')),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'messages.csv';
    link.click();
  };

  return (
    <div className="container mt-4">
      <h3 className="mb-4">Contact Messages</h3>
      <button className="btn btn-success mb-3" onClick={handleExportCSV}>
        Export to CSV
      </button>
      {messages.length === 0 ? (
        <p>No messages available.</p>
      ) : (
        <div className="table-responsive"> {/* Bootstrap class for responsive table */}
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Category</th>
                <th>Sent At</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((message) => (
                <tr key={message._id}>
                  <td>{message.name}</td>
                  <td>{message.email}</td>
                  <td>{message.subject}</td>
                  <td>{message.category}</td>
                  <td>{new Date(message.sentAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MessagesDashboard;
