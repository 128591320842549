import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { AuthProvider } from './Context/authContext'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './global.css';
import { BrowserRouter as Router } from 'react-router-dom'; 


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router> 
          
                <AuthProvider>
                    <App />
                </AuthProvider>
          
        </Router>
    </React.StrictMode>
);
