// src/components/ProjectList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Carousel, Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/ProjectList.css'; // Import your custom CSS for additional styling

const apiUrl = process.env.REACT_APP_API_URL;

function ProjectListUser() {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [itemsPerSlide, setItemsPerSlide] = useState(4);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/projects`);
        setProjects(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const updateItemsPerSlide = () => {
      if (window.matchMedia("(max-width: 767px)").matches) {
        setItemsPerSlide(1); // Mobile screens
      } else if (window.matchMedia("(max-width: 991px)").matches) {
        setItemsPerSlide(2); // Tablets and medium screens
      } else {
        setItemsPerSlide(4); // Laptops and larger screens
      }
    };

    updateItemsPerSlide();
    window.addEventListener('resize', updateItemsPerSlide);
    return () => window.removeEventListener('resize', updateItemsPerSlide);
  }, []);

  if (error) {
    return <p className="text-danger">Error: {error}</p>;
  }

  const groupProjects = (projects, itemsPerSlide) => {
    const grouped = [];
    for (let i = 0; i < projects.length; i += itemsPerSlide) {
      grouped.push(projects.slice(i, i + itemsPerSlide));
    }
    return grouped;
  };

  const groupedProjects = groupProjects(projects, itemsPerSlide);

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Our Projects</h2>
      <Carousel interval={3000} pause="hover" className="carousel-custom">
        {groupedProjects.map((group, index) => (
          <Carousel.Item key={index}>
            <Row className="d-flex justify-content-center">
              {group.map((project) => (
                <Col xs={12} sm={6} md={4} lg={3} key={project._id} className="mb-4">
                  <Link to={`/projects/${project._id}`} className="text-decoration-none">
                    <Card className="text-center">
                      <Card.Img
                        variant="top"
                        src={`${apiUrl}/uploads/${project.mainImage}`}
                        style={{ height: '200px', objectFit: 'cover' }}
                        alt={project.title}
                      />
                      <Card.Body>
                        <Card.Title>
                          <h5 className="card-title" style={{ height: "28px", overflow: "hidden", width: "100%" }}>
                            {project.title}
                          </h5>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
}

export default ProjectListUser;
