import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'; // Font Awesome icons

const TopBar = () => {
  return (
    <div className="bg-dark text-white-50 p-2">
      <Container>
        <Row className="align-items-center">
          {/* Left Side: Email, Address, Phone */}
          <Col xs={12} md={8} className="d-flex flex-wrap align-items-center justify-content-start mb-2 mb-md-0">
            <span className="me-3">info@albasitgoc.com</span>
            <span className="me-3">Civic Avenue Mall, Civic Center, Phase 4, Bahria Town, Islamabad</span>
            <span>0344 2422422</span>
          </Col>

          {/* Right Side: Follow Us and Social Media Icons */}
          <Col xs={12} md={4} className="d-flex align-items-center justify-content-end">
            {/* Follow Us and Social Media Icons on the Same Line */}
            <span className="me-2">Follow Us:</span>
            {/* Social Media Icons */}
            <a href="https://www.facebook.com/profile.php?id=100074705643184&mibextid=ZbWKwL " className="text-white mx-1 social-icon" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={24} />
            </a>
            <a href="https://www.instagram.com/albasitgoc " className="text-white mx-1 social-icon" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={24} />
            </a>
            <a href="https://youtube.com/@albasitgoc" className="text-white mx-1 social-icon" target="_blank" rel="noopener noreferrer">
              <FaYoutube size={24} />
            </a>
          </Col>
        </Row>
      </Container>

      {/* CSS Styling for Hover Effect */}
      <style jsx>{`
        .social-icon {
          padding: 6px;
          transition: background-color 0.3s, border-radius 0.3s, padding 0.3s;
          border-radius: 4px; /* Initial border radius */
        }
        .social-icon:hover {
          padding: 6px 10px; /* Adds padding to create a box effect */
          border-radius: 6px; /* Rounded corners on hover */
          background-color: #fff; /* Default hover background */
        }
        a[href*="facebook"]:hover {
          background-color: #3b5998;
          color: #fff;
        }
        a[href*="instagram"]:hover {
          background: linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4); /* Correct Instagram gradient */
          color: #fff;
        }
        a[href*="youtube"]:hover {
          background-color: #FF0000;
          color: #fff;
        }
      `}</style>
    </div>
  );
};

export default TopBar;
