// Header.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import '../style/Header.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Header = () => {
  const navigate = useNavigate();

  const navbarLinkStyle = {
    fontWeight: 'bold',
    fontSize: 'large',
    fontFamily: 'serif',
  };

  const brandTextStyle = {
    fontFamily: 'Poppins, serif',
    fontSize: '1.8rem',
    fontWeight: '700',
    color: '#333',
    textTransform: 'uppercase',
  };

  const handleSelect = (eventKey) => {
  
    navigate(`/projects?category=${eventKey}`);
  };

  const handleSelectcmp = (componentName) => {
    navigate(`/${componentName.toLowerCase()}`);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top p-2">
      <div className="container">
        <Link className="navbar-brand d-flex align-items-center" to="/home">
          <img src="/images/logo.png" alt="ALBASIT Logo" style={{ height: '80px', marginRight: '10px' }} />
          <span style={brandTextStyle}>ALBASIT</span>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/" style={navbarLinkStyle}>Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/aboutus" style={navbarLinkStyle}>About Us</Link>
            </li>
            <li className="nav-item">
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle variant="light" id="dropdown-basic" className="nav-link" style={navbarLinkStyle}>
                  Our Projects
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="all">All</Dropdown.Item>
                  <Dropdown.Item eventKey="upcoming">Upcoming</Dropdown.Item>
                  <Dropdown.Item eventKey="ongoing">Ongoing</Dropdown.Item>
                  <Dropdown.Item eventKey="completed">Completed</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Link to="/blogs" className="nav-link" style={navbarLinkStyle}>
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic" className="nav-link" style={navbarLinkStyle}>
                  Media
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleSelectcmp('Csr')}>CSR</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSelectcmp('VideoGallery')}>Video Gallery</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <Link to="/invest" className="btn btn-warning ms-auto">Book Now</Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
