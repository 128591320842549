import React from "react";
import Header from "../Components/User/Header";
import Footer from "../Components/User/Footer";
import Top from "../Components/User/Top";
import ImageTextSlider from "../Components/User/ImageTextSlider";
import {
  FaBuilding,
  FaHandsHelping,
  FaFutbol,
  FaBook,
  FaHotel,
  FaShoppingBag,
} from "react-icons/fa"; // Import icons from react-icons
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/App.css";
import "../index.css";
import "./style/Homepage.css";

import ProjectList from "../Components/User/ProjectListUser";
import RecentArticles from "../Components/User/RecentArticles";
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
// HomePage Component
const HomePage = () => {
  const Box = ({ icon, heading, description }) => (
    <div className="box text-center p-3 border rounded shadow-sm">
      <div className="box-icon mb-3">{icon}</div>
      <div className="box-heading mb-2">{heading}</div>
      <div className="box-description">{description}</div>
    </div>
  );

  return (
    <div>
      <Top />
     
      <Header />
      <ImageTextSlider />
     
      <div className="styled-container">
  <div className="home-content">
    <h2 className="left-part">Our Strengths</h2>
    <h5 className="left-part">Our Expertise Across 7 Industries</h5>
    <p className="right-part">
      We proudly collaborate with seven diverse industries, showcasing
      our broad expertise and innovative solutions across various
      sectors. Our diverse portfolio highlights our adaptability and
      commitment to excellence in every field we serve.
    </p>
  </div>
</div>


      <div className="box-container ">
        <div className="row">
          <div className="col-md-4 mb-4">
          <Link to="/projects?projectType=construction" className="text-decoration-none">
            <Box
              icon={<FaBuilding />}
              heading="Construction"
              description="Offering expert solutions for all construction needs."
            />
            </Link>
          </div>
          <div className="col-md-4 mb-4">
          <Link to="/projects?projectType=welfare" className="text-decoration-none">
            <Box
              icon={<FaHandsHelping />}
              heading="Welfare"
              description=" Providing impactful support for community welfare programs."
            />

            </Link>
          </div>
          <div className="col-md-4 mb-4">
          <Link to="/projects?projectType=sport" className="text-decoration-none">
            <Box
              icon={<FaFutbol />}
              heading="Sport"
              description="Delivering innovative services to enhance the sports sector."
            />
            </Link>
          </div>
          <div className="col-md-4 mb-4">
          <Link to="/projects?projectType=education" className="text-decoration-none">
            <Box
              icon={<FaBook />}
              heading="Education"
              description=" Offering comprehensive solutions to elevate educational experiences."
            />
            </Link>
          </div>
          <div className="col-md-4 mb-4">
          <Link to="/projects?projectType=hotel industry" className="text-decoration-none">
            <Box
              icon={<FaHotel />}
              heading="Hotel Industry"
              description=" Enhancing guest satisfaction with top-tier hotel industry services"
            />
            </Link>
          </div>
          <div className="col-md-4 mb-4">
          <Link to="/projects?projectType=commerical" className="text-decoration-none">
            <Box
              icon={<FaShoppingBag />}
              heading="Commercial"
              description="Driving business growth with tailored commercial solutions."
            />
            </Link>
          </div>
        </div>
      </div>
      <div class="circle-container">
        <div class="circle">
          <img
            src="/images/img (4).png"
            alt="Image inside circle"
            class="circle-image"
          />
        </div>

        <div class="col-md-4">
          <p class="right-part">
            We proudly collaborate with seven diverse industries, showcasing our
            broad expertise and innovative solutions across various sectors. Our
            diverse portfolio highlights our adaptability and commitment to
            excellence in every field we serve.
          </p>
        </div>
      </div>

      <div className="about-section d-flex align-items-center justify-content-start">
        <div className="text-container p-4">
          <h2 className="text-center mb-4">Since 2004</h2>
          <p className="text-center">
            With an emphasis on Real Estate Development, Al Basit Group of
            Companies is a multi-disciplinary organization involved in various
            industries. Operating since 2004 under the leadership of Honorable
            Chairman, Mr. Amjad Mehmood, the Group’s portfolio spans multiple
            industries including education, petroleum, real estate development,
            hospitality, and sports.
          </p>
          <p className="text-center">
            Al Basit Group has been continuously working toward providing
            innovative infrastructure solutions to society. Our projects are
            designed to satisfy modern lifestyle demands.
          </p>
          <p className="text-center">
            The secret of the rapid success of Al Basit Group lies in our
            sustained leadership and value-added services to our clients and
            business partners.
          </p>
        </div>
      </div>
      <div className="Philosophy text-center my-5 ">
        <h2 className="my-5">Our Philosophy</h2>
        <div className="row align-items-center justify-content-center">
        
          <div className="col-12 col-md-3 p-3">
            <h4>Vision</h4>
            <p>
              Our Vision is to achieve the highest degree of perfection in
              everything we do all the time.
            </p>
          </div>

          {/* Vertical Line */}
          <div className="col-12 col-md-1 d-none d-md-block text-center">
            <div className="vertical-line"></div>
          </div>

          {/* Mission */}
          <div className="col-12 col-md-3 p-3">
            <h4>Mission</h4>
            <p>
              Our Mission is to WOW all our stakeholders including clients,
              partners, investors, and employees through meaningful services.
            </p>
          </div>

          {/* Vertical Line */}
          <div className="col-12 col-md-1 d-none d-md-block text-center">
            <div className="vertical-line"></div>
          </div>

          {/* Motto */}
          <div className="col-12 col-md-3 p-3">
            <h4>Motto</h4>
            <p>
              Al Basit Group of Companies aims to maintain top-notch customer
              service and relationships and give back to society.
            </p>
          </div>
        </div>
      </div>
      <div class="crousel">
        <ProjectList />
      </div>
      <div class="">
        <RecentArticles />
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
