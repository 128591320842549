import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../style/Form.css';

const apiUrl = process.env.REACT_APP_API_URL;

function EditBlog() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [image, setImage] = useState(null);
  const [author, setAuthor] = useState('');

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/blogs/edit/${id}`);
        setBlog(response.data);
        setTitle(response.data.title);
        setDescription(response.data.description);
        setAuthor(response.data.author);
        setDate(response.data.date.split('T')[0]);
      } catch (error) {
        console.error('Error fetching blog:', error);
        alert('Failed to fetch blog details');
      }
    };

    fetchBlog();
  }, [id]);

  const handleFileChange = (e) => {
    if (e.target.files[0].size > 1 * 1024 * 1024) { // Check file size (1 MB limit)
      Swal.fire({
        icon: 'error',
        title: 'File too large',
        text: 'Please select a file smaller than 1 MB.',
      });
      return;
    }
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('date', date);
    formData.append('author', author);
    if (image) formData.append('image', image);

    try {
      await axios.put(`${apiUrl}/api/blogs/edit/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire({
        title: 'Success!',
        text: 'Blog updated successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/blogs');
      });
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update blog.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      console.error(error);
    }
  };

  if (!blog) return <p>Loading...</p>;

  return (
    <>
      <Link to="/blogs" className="btn btn-secondary mb-3">Back to Blogs</Link>
      <div className="form">
        <h2 className="form-header">Edit Blog</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <ReactQuill
              id="description"
              theme="snow"
              className="quill-editor"
              value={description}
              onChange={setDescription}
              required
            />
          </div>
          <br />
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              id="date"
              className="form-control"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="author">Author</label>
            <input
              type="text"
              id="author"
              className="form-control"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              required
            />
          </div>
          <div className="form-group"><p style={{ color: 'red' }}>Image must be less than 1MB </p>
            <label htmlFor="image">Upload Image</label>
            <input
              type="file"
              id="image"
              className="form-control"
              onChange={handleFileChange}
            />
            {blog.image && (
              <div>
                <img
                  src={`${apiUrl}/uploads/${blog.image}`}
                  alt="Blog"
                  width="100"
                />
              </div>
            )}
          </div>
          <button type="submit" className="btn btn-primary">Update Blog</button>
        </form>
      </div>
    </>
  );
}

export default EditBlog;
